import { BlockchainType } from '../../../enums/blockchain';
import config from "../../../config";
import bscscan, { account } from 'bsc-scan'
import axios from "axios";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import moment from "moment";
import { NumberUtils } from "../../../utils/NumberUtils";
import web3 from 'web3';
import { InvalidAddress } from '../BlockchainDataService';
var Cryptoapis = require('cryptoapis');

bscscan.setUrl(config.bsc.url)
bscscan.setApiKey(config.bsc.apiKey)

class BscService {
    private bscApi = axios.create({
        baseURL: config.bsc.url,
    });
    pageSize = 1000; // max 1000

    private cryptoapis;
    private blockchain = 'binance-smart-chain';
    private network = config.cryptoapis.network;
    constructor() {
        Cryptoapis.ApiClient.instance.authentications['ApiKey'].apiKey = config.cryptoapis.apiKey;
        this.cryptoapis = new Cryptoapis.InternalApi();
    }

    async getAddressData(address: string): Promise<BscAddressData> {
        this.validateAddress(address);
        var balanceResult = await account.getBnbBalance(address);
        var transactionList = await this.loadTransactionList(address, 1);
        var internalTransactionList = await this.loadInternalTransactionList(address, 1);
        var transactionCountResult = await this.bscApi.get('/api', {
            params: {
                module: 'proxy',
                action: 'eth_getTransactioncount',
                address: address,
                apiKey: config.bsc.apiKey,
            }
        });
        // var internalTransactionCountResult = await this.cryptoapis.listInternalTransactionsByAddress(this.blockchain, this.network, address, { limit: 1 });
        // console.log("test")
        // console.log(internalTransactionCountResult)
        return {
            type: BlockchainType.BSC,
            address: address,
            balance: parseInt(balanceResult as string) / 1000000000000000 / 1000,
            transactionData: {
                totalCount: parseInt(transactionCountResult.data.result, 16),
                page: 1,
                pageSize: this.pageSize,
                items: transactionList,
            },
            internalTransactionData: {
                totalCount: /*internalTransactionCountResult.data.total*/0,
                page: 1,
                pageSize: this.pageSize,
                items: internalTransactionList,
            },
        }
    }

    async loadTransactionList(address: string, page: number): Promise<any[]> {
        var transactionList = await account.getTransactions(address, { page: page, offset: this.pageSize, sort: 'desc', })
        return (transactionList as Array<any>).map(this.mapTransaction);
    }

    async loadInternalTransactionList(address: string, page: number): Promise<any[]> {
        var transactionListResult = await this.bscApi.get('/api', {
            params: {
                module: 'account',
                action: 'txlistinternal',
                address: address,
                page: page,
                offset: this.pageSize,
                sort: 'desc',
                apiKey: config.bsc.apiKey,
            }
        });
        return transactionListResult.data.result.map(this.mapTransaction);
    }

    private mapTransaction(transaction: { timeStamp: number; hash: any; blockNumber: number; isError: number; }) {
        var blockDateTime;
        var blockAge;
        if (transaction.timeStamp) {
            var t = new Date(transaction.timeStamp * 1000);
            blockDateTime = moment(t).format("yyyy.MM.DD hh:mm");
            blockAge = DateTimeUtils.timeSince(transaction.timeStamp * 1000);
        }
        return {
            hash: transaction.hash,
            block: NumberUtils.numberWithCommas(transaction.blockNumber),
            timestamp: transaction.timeStamp,
            blockDateTime: blockDateTime,
            blockAge: blockAge,
            status: transaction.isError === 0 ? 'Failed' : 'Success',
        }
    }

    private validateAddress(address: string) {
        try {
            web3.utils.toChecksumAddress(address)
        } catch (e) {
            throw new InvalidAddress(`Address ${address} is invalid`)
        }
    }
}

const bscService = new BscService()
export interface BscAddressData {
    type: BlockchainType.BSC,
    address: string,
    balance: number,
    transactionData: {
        totalCount: number,
        page: number,
        pageSize: number,
        items: any[],
    },
    internalTransactionData: {
        totalCount: number,
        page: number,
        pageSize: number,
        items: any[],
    },
}
export {
    bscService,
}
