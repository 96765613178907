import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Cookies } from "react-cookie";
import config from "../../config";
import { toTokens } from "../../utils/NumberUtils";

class SolanaPaymentService {
    private connection = new Connection(config.solana.cluster);

    async processPayment(
        fromAddress: string,
        amount: number,
        signTransaction: (transaction: Transaction) => Promise<Transaction>,
        coin: string,
    ) {

        let tokenDetails;
        switch (coin) {
            case 'usdc':
                tokenDetails = config.solana.usdc;
                break;
            case 'usdt':
                tokenDetails = config.solana.tether;
                break;
            default:
                throw new Error('Unsupported coin');
        }

        const tokenAmount = toTokens(amount, tokenDetails.decimals);

        let transaction
        try {
            transaction = await this.createTransaction(fromAddress, config.solana.recipientAddress, tokenAmount, tokenDetails.address)
        } catch (e: any) {
            console.error(e)
            if (e.message === 'Failed to find account') {
                return { error: `Token ${tokenDetails.address} is missing in your wallet` }
            }
            return { error: `Transaction failed` }
        }
        transaction = await signTransaction(transaction);

        let transactionSignature
        try {
            transactionSignature = await this.connection.sendRawTransaction(transaction.serialize());
        } catch (e: any) {
            console.error(e)
            return { error: `Transaction failed` }
        }

        return { transactionSignature };
    }

    async sendRawTransaction(transaction: Transaction) {
        return this.connection.sendRawTransaction(transaction.serialize());
    }

    private async createTransaction(fromAddress: string, toAddress: string, amount: number, tokenMintAddress: string): Promise<Transaction> {
        const mintPublicKey = new PublicKey(tokenMintAddress);
        const fromPubkey = new PublicKey(fromAddress)
        const toPubkey = new PublicKey(toAddress)
        const mintToken = new Token(
            this.connection,
            mintPublicKey,
            TOKEN_PROGRAM_ID,
            // normally the wallet owner will pay to transfer and to create recipients associated token account if it does not yet exist.
            // we mock this with empty data because we control the recipients
            { publicKey: new PublicKey(fromAddress), secretKey: new Uint8Array() }
        );

        // Create associated token accounts for my token if they don't exist yet
        var fromTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
            fromPubkey
        )
        var toTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
            toPubkey
        )

        let transaction = new Transaction();
        transaction.add(
            Token.createTransferInstruction(
                TOKEN_PROGRAM_ID,
                fromTokenAccount.address,
                toTokenAccount.address,
                fromPubkey,
                [],
                amount,
            )
        );
        transaction.feePayer = fromPubkey;
        const anyTransaction: any = transaction;
        anyTransaction.recentBlockhash = (await this.connection.getRecentBlockhash()).blockhash;
        return transaction;
    }
}
const paymentService = new SolanaPaymentService()

export {
    paymentService,
}
