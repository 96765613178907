import { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import iconLoader from "../../images/icon-feather-loader.svg";
import { enrexApi } from '../../services/EnrexApiService';
import { isValidEmail } from '../../utils/StringUtils';
import LoadingButton from '../loading/LoadingButton';

interface EmailValidationProps {
}

const EmailValidation: FC<EmailValidationProps> = (props) => {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);
    const [newValidation, setNewValidation] = useState<boolean>(false)
    const [emailInputError, setEmailInputError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [validatingEmail, setValidatingEmail] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [emailValidationError, setEmailValidationError] = useState<string>();

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get('id');
        if (id) {
            validate(id)
        } else {
            setNewValidation(true)
        }
    }, []);

    const validate = async (id: string) => {
        try {
            const result = await enrexApi.post('/validateAccount', {
                params: {
                    id,
                },
            })
            setCookie('validatedEmails', { email: result.data.email, }, { path: '/', sameSite: true, secure: true });
            history.push({
                pathname: '/myGreenNfts',
            });
        } catch (e: any) {
            if (e.response.data.error) {
                setEmailValidationError(e.response.data.error)
            } else {
                setEmailValidationError('Something went wrong')
            }
            console.error(e.response.data)
        }
    }

    const sendValidationEmail = async () => {
        if (!isValidEmail(email)) {
            setEmailInputError(true)
            return;
        }
        setEmailSent(false)
        setEmailValidationError(undefined)
        setValidatingEmail(true)

        const result = await enrexApi.post('/sendNftValidationEmail', null, {
            params: {
                email: email,
            },
        })
        if (result.data.error) {
            if (result.data.error === 'Could not find an account with this email') {
                setEmailValidationError('Could not find an account with this email')
            }
        } else {
            setEmailSent(true)
        }
        setValidatingEmail(false)
    }

    const onEmailInputChange = (value: string) => {
        setEmail(value)
        setEmailInputError(!isValidEmail(email))
    }

    return (
        <>
            {newValidation &&
                <div className="dapp-container">
                    <label className="content-container-inner-label">enter your email address to receive verification email</label>
                    <div className="content-container-inner">
                        <input type="text"
                            placeholder="Email address"
                            onChange={(event: any) => { onEmailInputChange(event.target.value); }}
                            value={email}
                            className={emailInputError ? 'invalid' : ''} />
                    </div>

                    {emailValidationError &&
                        <div className={'content-container-inner'}>
                            <p className='note'>⛔ {emailValidationError}</p>
                        </div>
                    }

                    {emailSent &&
                        <div>
                            <label className="content-container-inner-label">follow the link via your email</label>
                            <div className={'content-container-inner'}>
                                <p className='note'>✅ An email has been sent to your email address containing an activation link.
                                    Please click on the link to activate your account.
                                </p>
                            </div>
                        </div>
                    }

                    <div className="container-inner">
                        <div className="right-align-items">
                            <LoadingButton onClick={() => sendValidationEmail()} buttonText={emailSent ? 'resend email' : 'submit'} isLoading={validatingEmail} />
                        </div>
                    </div>
                </div>
            }
            {!newValidation &&

                <div className="dapp-container center-vertical center-horizontal">
                    {!emailValidationError &&
                        <div className={'content-container-inner'}>
                            <p className='note'>
                                Please wait while we validate your email. You will be redirected shortly.
                                <img src={iconLoader} className='inline-loader rotate' />
                            </p>
                        </div>
                    }
                    {emailValidationError &&
                        <div className={'content-container-inner'}>
                            <p className='note'>⛔ {emailValidationError}</p>
                        </div>
                    }
                </div>

            }
        </>
    )
};

export default EmailValidation;
