import { BlockchainName, BlockchainType, BlockchainValues } from "../enums/blockchain";

import cryptoETH from '../images/crypto-icons/eth.svg';
import cryptoBSC from '../images/crypto-icons/bsc.svg';
import cryptoBTC from '../images/crypto-icons/btc.svg';
import cryptoDOGE from '../images/crypto-icons/doge.svg';
import cryptoSOL from '../images/crypto-icons/sol.svg';
import cryptoEnrex from '../images/crypto-icons/enrex.png';
import cryptoArweave from '../images/crypto-icons/arweave.png';

class BlockChainUtils {
    static getIcon(blockChain: BlockchainValues) {
        let icon = cryptoEnrex;
        switch (blockChain) {
            case BlockchainValues.BSC:
                icon = cryptoBSC;
                break;
            case BlockchainValues.BTC:
                icon = cryptoBTC;
                break;
            case BlockchainValues.DOGE:
                icon = cryptoDOGE;
                break;
            case BlockchainValues.SOL:
                icon = cryptoSOL;
                break;
            case BlockchainValues.ETH:
                icon = cryptoETH;
                break;
            case BlockchainValues.ARWEAVE:
                icon = cryptoArweave;
                break;
            default:
                break;
        }
        return icon;
    }
    static getName(blockChain: BlockchainValues) {
        let type = BlockChainUtils.getKeyByValue(BlockchainValues, blockChain.toString()) as BlockchainName;
        return BlockChainUtils.getValueByKey(BlockchainName, type);
    }
    static getKeyByValue(object: any, value: string) {
        return Object.keys(object).find(key => object[key] === value);
    }
    static getValueByKey(object: any, key: string) {
        return (object as unknown as any)[key];
    }

}

export default BlockChainUtils;
