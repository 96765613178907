import logo from './images/logo.svg';
import logoText from './images/logo-text.svg';
import iconLeaf from './images/icon-leaf.svg';
import iconAdd from './images/icon-add.svg';
import iconMyAcc from './images/icon-my-acc.svg';
import iconLogout from './images/icon-logout.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
    useHistory
} from "react-router-dom";
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import NFTOffset from './components/offset/nft/NFTOffset';
import MyOffsets from './components/offset/nft/MyOffsets';
import OffsetList from './components/offset/nft/OffsetList';
import EmailValidation from './components/validation/EmailValidation';
import Logout from './components/logout/Logout';
import BadgeDetails from './components/offset/nft/BadgeDetails';

function Content(props: any): JSX.Element {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);
    const [menuActive, setMenu] = useState<boolean>(false);

    const handleLink = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    return (
        <Router>
            <div className="root-container">
                <div className='navWrapper'>
                    <nav>
                        <div className="menu-logo">
                            <a ><img className='menu-logo-img' src={logo} alt='' /><img className='menu-full-logo-img' src={logoText} alt='' /></a>
                        </div>
                        <div className="menu-nav">
                            <ul>
                                {!menuActive &&
                                    <li>
                                        <NavLink
                                            to="/home"
                                            activeClassName="selected"
                                            onClick={(event: any) => { return menuActive ? handleLink(event) : null; }}>
                                            <div className="menu-item-icon"><img src={iconAdd} alt='' /></div>
                                            <div className='menuName'>Offset NFT Collection</div>
                                        </NavLink >
                                    </li>
                                }
                                <li>
                                    <NavLink to="/members" activeClassName="selected">
                                        <div className="menu-item-icon"><img src={iconLeaf} alt='' /></div>
                                        <div className='menuName'>Green NFTs</div>
                                    </NavLink >
                                </li>
                                <li>
                                    <NavLink to="/myGreenNfts" activeClassName="selected">
                                        <div className="menu-item-icon"><img src={iconMyAcc} alt='' /></div>
                                        <div className='menuName'>My Green NFTs</div>
                                    </NavLink >
                                </li>
                            </ul>
                        </div>
                        <div className="menu-footer">
                            <ul>
                                {cookies.validatedEmails &&
                                    <li>
                                        <NavLink
                                            to="/logout"
                                            activeClassName="selected">
                                            <div className="menu-item-icon"><img src={iconLogout} alt='' /></div>
                                            <div className='menuName'>Log Out</div>
                                        </NavLink >
                                    </li>

                                }
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="content-container">
                    <Switch>
                        <Route path="/home">
                            <NFTOffset></NFTOffset>
                        </Route>
                        <Route path="/members">
                            <OffsetList></OffsetList>
                        </Route>
                        <Route path="/myGreenNfts">
                            <MyOffsets></MyOffsets>
                        </Route>
                        <Route path="/emailValidation">
                            <EmailValidation></EmailValidation>
                        </Route>
                        <Route path="/nft">
                            <BadgeDetails></BadgeDetails>
                        </Route>
                        <Route path="/logout">
                            <Logout></Logout>
                        </Route>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return (
                                    <Redirect to="/home" />
                                )
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </Router >
    );
}

export default Content;