export const round = (num: number, precision: number = 2) => {
    return Math.round((num + Number.EPSILON) * 10 ** precision) / 10 ** precision
}

export const toTokens = (amount: number, decimals: number) => {
    return amount * 10 ** decimals;
}

export const fromTokens = (amount: number, decimals: number) => {
    return amount / 10 ** decimals;
}
export class NumberUtils {
    static numberWithCommas(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}