import { FC, useEffect, useState } from 'react';
import solanaIcon from '../../../../images/crypto-icons/sol.svg';
import bscIcon from '../../../../images/crypto-icons/bsc.svg';

import usdcIcon from '../../../../images/crypto-icons/usdc.png';
import tetherIcon from '../../../../images/crypto-icons/tether.png';
import busdIcon from '../../../../images/crypto-icons/busd.png';
import infoIcon from '../../../../images/icon-info.svg';
import iconError from '../../../../images/icon-error.svg';
import { useWallet, Wallet } from '@solana/wallet-adapter-react';
import { WalletName, WalletNotReadyError } from '@solana/wallet-adapter-base';

import iconPhantom from '../../../../images/wallets/icon-phantom.png';
import iconSolflare from '../../../../images/wallets/icon-solflare.png';
import iconSollet from '../../../../images/wallets/icon-sollet.png';
import iconSlope from '../../../../images/wallets/icon-slope.png';
import iconTorus from '../../../../images/wallets/icon-torus.png';
import iconLedger from '../../../../images/wallets/icon-ledger.png';
import iconMetamask from '../../../../images/wallets/icon-metamask.png';
import iconSolletExtension from '../../../../images/wallets/icon-sollet-extension.png';
import { paymentService as solanaPaymentService } from '../../../../services/payment/SolanaPaymentService';
import { enrexApi } from '../../../../services/EnrexApiService';
import NFTEstimateOverview from '../NFTEstimateOverview';
import { paymentService as binancePaymentService } from '../../../../services/payment/BinancePaymentService';
import NFTOffsetOverview from '../NFTOffsetOverview';
import config from '../../../../config';
import { getLatestOffset } from '../../../../utils/OffsetUtils';

interface OffsetPaymentStageProps {
    offset: any,
    refreshDataCallback: () => void,
}

const OffsetPaymentStage: FC<OffsetPaymentStageProps> = (props) => {
    const { select, publicKey, signTransaction, wallets, wallet, connect, connected, disconnect } = useWallet();

    const [selectedNetwork, setNetwork] = useState<string>();
    const [selectedCoin, setCoin] = useState<string>();
    const [selectedWallet, setSelectedWallet] = useState<string>();
    const [goGreen, setGoGreen] = useState<boolean>(false);
    const [walletNotReadyError, setWalletNotReadyError] = useState<boolean>(false);
    const [certificateUnavailable, setCertificateUnavailable] = useState<boolean>(false);
    const [processedPayment, setProcessedPayment] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (processedPayment) {
            setGoGreen(false)
            setProcessedPayment(false)
        }
    }, [props.offset]);

    const onWalletClick = async (walletName: WalletName | string) => {
        if (selectedNetwork === 'solana') {
            setSelectedWallet(walletName)
            select(walletName as WalletName)
        } else if (selectedNetwork === 'binance') {
            setSelectedWallet(walletName)
            processPayment();
        } else {
            throw new Error('Not implemented');
        }
    }

    useEffect(() => {
        if (publicKey && selectedWallet === wallet?.adapter.name && selectedNetwork === 'solana') {
            processPayment();
        }
    }, [publicKey, selectedWallet]);

    useEffect(() => {
        if (wallet?.adapter && !connected && selectedWallet) {
            setWalletNotReadyError(false)
            connect().catch((e) => {
                if (e instanceof WalletNotReadyError) {
                    setWalletNotReadyError(true)
                }
            })
        }
    }, [wallet, selectedWallet]);

    const processPayment = async () => {
        if (!selectedCoin || !selectedNetwork) {
            return;
        }
        setError(undefined)
        setSelectedWallet(undefined)
        const certificateAvailability = await enrexApi.get('/isCertificateAvailable', {
            params: {
                amount: Math.ceil(props.offset.energy / 1000) // 1mwh is minimum
            }
        });
        if (!certificateAvailability.data.status) {
            setCertificateUnavailable(true)
            return;
        } else {
            setCertificateUnavailable(false)
        }
        let signature;
        const { transactionSignature, error } = (await sendPayment())!;
        if (error) {
            setError(error);
        } else {
            signature = transactionSignature;
        }
        if (signature) {
            const result = await enrexApi.post('/saveNftTransactionSignature', null, {
                params: {
                    id: props.offset.id,
                    transactionSignature: signature,
                    paymentNetwork: selectedNetwork,
                },
            })
            props.refreshDataCallback();
            setProcessedPayment(true);
        }
    }

    const sendPayment = async () => {
        if (!selectedCoin || !selectedNetwork) {
            return;
        }
        if (selectedNetwork === 'solana') {
            if (!publicKey || !signTransaction) {
                return;
            }
            return await solanaPaymentService.processPayment(publicKey.toString(), props.offset.price, signTransaction, selectedCoin)
        } else if (selectedNetwork === 'binance') {
            return await binancePaymentService.processPayment(props.offset.price, selectedCoin);
        } else {
            throw new Error('Not implemented');
        }
    }

    const getWalletIcon = (wallet: string) => {
        if (wallet === 'Phantom') {
            return iconPhantom;
        } else if (wallet === 'Solflare') {
            return iconSolflare;
        } else if (wallet === 'Sollet') {
            return iconSollet;
        } else if (wallet === 'Slope') {
            return iconSlope;
        } else if (wallet === 'Torus') {
            return iconTorus;
        } else if (wallet === 'Ledger') {
            return iconLedger;
        } else {
            return iconSolletExtension;
        }
    }

    return (
        <>
            <label className="content-container-inner-label">energy consumption estimate for your nft project</label>
            {props.offset.offsets?.length > 0
                ? <NFTOffsetOverview data={props.offset} detailed></NFTOffsetOverview>
                : <NFTEstimateOverview data={props.offset}></NFTEstimateOverview>
            }
            {!goGreen &&
                <>
                    {(!props.offset.offsets
                        || props.offset.offsets?.length === 0
                        || getLatestOffset(props.offset).transactionStatus === 'failed')
                        &&
                        <div className="container-inner">
                            <div className="right-align-items">
                                <button onClick={() => setGoGreen(true)} className='hoverBorder' >{!props.offset.offsets || props.offset.offsets?.length === 0 ? "let's go green" : 'try again'}</button>
                            </div>
                        </div>
                    }
                </>
            }
            {goGreen &&
                <>

                    {certificateUnavailable &&
                        <div className={'content-container-inner'}>
                            <p className='note'>
                                <img src={iconError} />
                                Please try again later or contact support
                            </p>
                        </div>
                    }

                    {error &&
                        <div className={'content-container-inner'}>
                            <p className='note'>
                                <img src={iconError} />
                                {error}
                            </p>
                        </div>
                    }

                    <label className="content-container-inner-label">select a network</label>
                    <div className="content-container-inner">
                        <div className='button-container'>
                            <button
                                onClick={() => setNetwork('solana')}
                                className={`icon-button selectable ${selectedNetwork === 'solana' ? 'selected' : ''}`}>
                                <img src={solanaIcon} alt='' />
                                solana
                            </button>
                            <button
                                onClick={() => setNetwork('binance')}
                                className={`icon-button selectable ${selectedNetwork === 'binance' ? 'selected' : ''}`}>
                                <img src={bscIcon} alt='' />
                                binance smart chain
                            </button>
                        </div>
                    </div>

                    {selectedNetwork &&
                        <>
                            <label className="content-container-inner-label">select a stable coin</label>
                            <div className="content-container-inner">
                                <div className='button-container'>
                                    <button
                                        onClick={() => setCoin('usdt')}
                                        className={`icon-button selectable ${selectedCoin === 'usdt' ? 'selected' : ''}`}>
                                        <img src={tetherIcon} alt='' />
                                        usdt
                                    </button>
                                    <button
                                        onClick={() => setCoin('usdc')}
                                        className={`icon-button selectable ${selectedCoin === 'usdc' ? 'selected' : ''}`}>
                                        <img src={usdcIcon} alt='' />
                                        usdc
                                    </button>
                                    {selectedNetwork === 'binance' &&
                                        <button
                                            onClick={() => setCoin('busd')}
                                            className={`icon-button selectable ${selectedCoin === 'busd' ? 'selected' : ''}`}>
                                            <img src={busdIcon} alt='' />
                                            busd
                                        </button>
                                    }
                                </div>
                            </div>
                        </>
                    }

                    {selectedCoin &&
                        <>
                            <label className="content-container-inner-label">select wallet</label>
                            {walletNotReadyError &&
                                <div className={'content-container-inner invalid'}>
                                    <p className='note'>
                                        <img src={infoIcon} />
                                        Wallet extension not found. Try installing the one you have chosen or try choosing another wallet option.
                                    </p>
                                </div>
                            }

                            <div className="content-container-inner">
                                <div className='button-container'>
                                    {selectedNetwork === 'solana' &&
                                        <>
                                            {wallets.map((wallet) => {
                                                return <button onClick={() => onWalletClick(wallet.adapter.name)}
                                                    className='icon-button selectable'
                                                    key={wallet.adapter.name}>
                                                    <img src={getWalletIcon(wallet.adapter.name)} alt='' />
                                                    {wallet.adapter.name}
                                                </button>
                                            })}
                                        </>
                                    }
                                    {selectedNetwork === 'binance' &&
                                        <>
                                            <button
                                                onClick={() => onWalletClick('metamask')}
                                                className={`icon-button`}>
                                                <img src={iconMetamask} alt='' />
                                                metamask
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
};

export default OffsetPaymentStage;