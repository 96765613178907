import { Connection, PublicKey } from "@solana/web3.js";
import config from '../../../config';

let apiUrl: string;
apiUrl = config.solana.cluster;

class InvalidAddress extends Error { }

class SolanaService {
    private solanaConnection = new Connection(apiUrl, 'finalized');
    pageSize = 1000;
    async getTxCount(address: string, isCancelled: () => boolean): Promise<number> {
        let count = 0;
        let previousItem;
        let addressTxList;
        while (true && !isCancelled()) {
            try {
                addressTxList = await this.loadTransactionList(address, previousItem);
                if (addressTxList.length === 0) {
                    break;
                }
                count += addressTxList.length;
                previousItem = addressTxList[addressTxList.length - 1];
                this.sleep(4000);
            } catch (ex) {
                break;
            }
        }
        return count;
    }

    async loadTransactionList(address: string, before?: any): Promise<any[]> {
        var publicKey = this.getPublicKeyFromAddress(address);
        var transactionListResult = await this.solanaConnection.getSignaturesForAddress(
            publicKey,
            {
                limit: this.pageSize,
                before: before?.signature,
            },
        );
        return transactionListResult.map(transaction => {
            var blockDateTime;
            var blockAge;
            if (transaction.blockTime) {
                var t = new Date(transaction.blockTime * 1000);
                blockDateTime = ''/*moment(t).format("yyyy.MM.DD hh:mm")*/;
                blockAge = transaction.blockTime * 1000;
            }
            return {
                signature: transaction.signature,
                slot: this.numberWithCommas(transaction.slot),
                blockTime: transaction.blockTime,
                blockDateTime: blockDateTime,
                blockAge: blockAge,
                status: transaction.err ? 'Failed' : 'Success',
            }
        });
    }

    getPublicKeyFromAddress(address: string) {
        try {
            return new PublicKey(address);
        } catch (e) {
            throw new InvalidAddress(`Address ${address} is invalid`)
        }
    }

    numberWithCommas(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

const solanaService = new SolanaService()

export interface SolanaAddressData {
    type: string,
    address: string,
    balance: number,
    tokenCount: number,
    transactionData: {
        page: number,
        pageSize: number,
        items: any[],
    },
}

export {
    solanaService,
}