import axios from 'axios';
import config from '../config';

const enrexApi = axios.create({
    baseURL: config.enrexApi,
    // timeout: 1000,
});

export {
    enrexApi
}