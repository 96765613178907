export const isValidEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        );
};

export const isValidHttpUrl = (string: string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const truncateMiddle = (string: string, start: number = 7, end: number = 7) => {
    return string.substring(0, start) + "..." + string.substring(string.length - end, string.length)
}

export function formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(new RegExp(`\\{${index}}`, 'g'), val[index]);
    }
    return str;
}