import { useEffect, useState } from 'react';
import iconError from '../../../images/icon-error.svg';
import iconSearch from '../../../images/icon-search.svg';
import { enrexApi } from '../../../services/EnrexApiService';
import LoadingList from '../../loading/LoadingList';
import NFTOffsetOverview from './NFTOffsetOverview';

const OffsetList = () => {
    const [offsetList, setOffsetList] = useState<any>([]);
    const [loadingList, setLoadingList] = useState<boolean>(false);

    useEffect(() => {
        search();
    }, []);

    const onSearchChange = (event: any) => {
        search(event.target.value)
    }

    const search = async (searchValue?: string) => {
        setLoadingList(true)
        const result = await enrexApi.get('/nftHistory', {
            params: {
                search: searchValue,
                certificateStatus: 'confirmed',
            },
        })
        setOffsetList(result.data);
        setLoadingList(false)
    }


    return (
        <div className="dapp-container">
            <label className="content-container-inner-label">search NFTs that are already green</label>
            <div className="content-container-inner">
                <div className="input-icons">
                    <img className="icon" src={iconSearch} alt='' />
                    <input type="search" placeholder="NFT collection name" onChange={onSearchChange} className='search' />
                </div>
            </div>

            <LoadingList isEmpty={offsetList?.length === 0}
                isLoading={loadingList}>
                {offsetList?.map((element: any, i: number) => {
                    return <NFTOffsetOverview data={element} key={i} />
                }
                )}
            </LoadingList>
        </div>
    )
};

export default OffsetList;
