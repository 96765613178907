import { clusterApiUrl } from "@solana/web3.js";
const dev = {
    // enrexApi: 'https://api.dapp.enrex.io/',
    enrexApi: 'http://localhost:8000/',
    assetsUrl: 'https://assets-test.enrex.io',
    solana: {
        cluster: clusterApiUrl('devnet'),
        usdc: {
            address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
            decimals: 6,
        },
        tether: {
            address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB ',
            decimals: 6,
        },
        recipientAddress: 'LRKLinNRH9p4NcSm9kdn6GFZLQEMK7CwT8MvXurcsaf',
        explorerUrl: `https://explorer.solana.com/tx/{0}`,
    },
    bsc: {
        apiKey: 'CMKMEZUCDXH4N31TMSMQRB7FTNPTPMBYG2',
        url: 'https://api.bscscan.com/',
    },
    cryptoapis: {
        apiKey: '3ac6cdd6c2b8ff69e6bb388d878fd287b66d1494',
        network: 'mainnet',
    },
    binance: {
        usdc: {
            address: '0x64544969ed7EBf5f083679233325356EbE738930',
            decimals: 18,
        },
        usdt: {
            address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
            decimals: 18,
        },
        busd: {
            address: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
            decimals: 18,
        },
        recipientAddress: '0x4Ee8cFee32f83130A7Ed818FC8A5E9380B5eCe8A',
        explorerUrl: `https://testnet.bscscan.com/tx/{0}`,
    },
    pipedrive: {
        id: 20
    }
};

const test = {
    enrexApi: 'https://medeina.api.dapp.enrex.io/',
    assetsUrl: 'https://assets-test.enrex.io',
    solana: {
        cluster: clusterApiUrl('devnet'),
        usdc: {
            address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
            decimals: 6,
        },
        tether: {
            address: 'HY6uvCfBQhKANRxBcYLBK7aUva8mT7mLP2SjrLLmipza',
            decimals: 9,
        },
        recipientAddress: 'DcnmBarF7vLbavWv6rUeEYTxVscRdh8umKsSTYEcVxt5',
        explorerUrl: `https://explorer.solana.com/tx/{0}?cluster=devnet`,
    },
    bsc: {
        apiKey: 'CMKMEZUCDXH4N31TMSMQRB7FTNPTPMBYG2',
        url: 'https://api.bscscan.com/',
    },
    cryptoapis: {
        apiKey: '3ac6cdd6c2b8ff69e6bb388d878fd287b66d1494',
        network: 'mainnet',
    },
    binance: {
        usdc: {
            address: '0x64544969ed7EBf5f083679233325356EbE738930',
            decimals: 18,
        },
        usdt: {
            address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
            decimals: 18,
        },
        busd: {
            address: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
            decimals: 18,
        },
        recipientAddress: '0x4Ee8cFee32f83130A7Ed818FC8A5E9380B5eCe8A',
        explorerUrl: `https://testnet.bscscan.com/tx/{0}`,
    },
    pipedrive: {
        id: 20
    }
}

const prod = {
    enrexApi: 'https://api.dapp.enrex.io/',
    assetsUrl: 'https://assets.enrex.io',
    solana: {
        cluster: 'https://solitary-crimson-bird.solana-mainnet.quiknode.pro/39cd0952fe1e01cead4b69f4637ceb3b8e7d96e0/',
        usdc: {
            address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
            decimals: 6,
        },
        tether: {
            address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB ',
            decimals: 6,
        },
        
        // real recipient address
        recipientAddress: 'EiwWdLXzHG9PpJUfy1BswWrLo3vNE3wU7TwJJPa2TW1Y', 
        
        // Testing recpient in PRod
        // recipientAddress: 'LRKLinNRH9p4NcSm9kdn6GFZLQEMK7CwT8MvXurcsaf',  
        
        explorerUrl: `https://explorer.solana.com/tx/{0}`,
    },
    bsc: {
        apiKey: 'CMKMEZUCDXH4N31TMSMQRB7FTNPTPMBYG2',
        url: 'https://api.bscscan.com/',
    },
    cryptoapis: {
        apiKey: '3ac6cdd6c2b8ff69e6bb388d878fd287b66d1494',
        network: 'mainnet',
    },
    binance: {
        usdc: {
            address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            decimals: 18,
        },
        usdt: {
            address: '0x55d398326f99059ff775485246999027b3197955',
            decimals: 18,
        },
        busd: {
            address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            decimals: 18,
        },
        recipientAddress: '0xd60Df8Dd54e47E024d2Fb760583a321ae3583D29',         
        explorerUrl: `https://bscscan.com/tx/{0}`,
    },
    pipedrive: {
        id: 20
    }
};

const config = {
    // Add common config values here
    // Default to dev if not set
    ...(process.env.REACT_APP_ENV === "prod" ? prod : process.env.REACT_APP_ENV === "test" ? test : dev),
};

export default config;
