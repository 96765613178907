import { FC, useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface StatusIndicatorTooltipProps {
    tooltip: string,
}
const StatusIndicatorTooltip: FC<StatusIndicatorTooltipProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Popover
            isOpen={open}
            positions={['top']}
            padding={10}
            reposition={false}
            content={<div>{props.tooltip}</div>}>
            <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                {props.children}
            </div>
        </Popover>
    )
};

export default StatusIndicatorTooltip;
