import { BlockchainType } from '../../enums/blockchain';

class InvalidAddress extends Error { }
export interface ExrexApiAddressData {
    type: BlockchainType.ETH | BlockchainType.BSC | BlockchainType.DOGE,
    address: string,
    balance: number,
    tokenCount?: number,
    transactionData: {
        totalCount: number,
        page: number,
        pageSize: number,
        items: any[],
    },
    internalTransactionData: {
        totalCount: number,
        page: number,
        pageSize: number,
        items: any[],
    },
}

export {
    InvalidAddress
}