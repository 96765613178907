
import { FC, ReactNode } from 'react';
import iconError from '../../images/icon-error.svg';

interface LoadingWithErrorProps {
    isLoading?: boolean
    isError?: boolean
    error?: ReactNode
}
const LoadingWithError: FC<LoadingWithErrorProps> = (props) => {
    return (
        <>
            {props.isLoading &&
                <div className='center-horizontal'>
                    <div className='loadingIndicator dot-flashing inline'></div>
                </div>
            }
            {!props.isLoading && props.isError &&
                <>
                    {props.error &&
                        props.error
                    }
                    {!props.error &&
                        <div className='horizontalContainer'>
                            <img src={iconError} alt='' />
                            <label className='noPadding'>Not found</label>
                        </div>
                    }
                </>
            }
            {!props.isLoading && !props.isError &&
                props.children
            }
        </>

    )
};

export default LoadingWithError;
