import { FC, useEffect, useState } from 'react';
import styles from './Offsets.module.css';
import StatusIndicatorTooltip from './status/StatusIndicatorTooltip';
import iconPendingPayment from "../../../images/icon-pending-payment.png";
import iconPaymentFailed from "../../../images/icon-failed-payment.png";
import iconPendingCertificate from "../../../images/icon-pending-certificate.png";
import iconCertificateFailed from "../../../images/icon-failed-certificate.png";
import iconSuccess from "../../../images/icon-success.png";
import iconTwitter from '../../../images/social-media/twitter.svg';
import iconDiscord from '../../../images/social-media/discord.svg';
import NFTOffsetDetails from './NFTOffsetDetails';
import { BlockchainName, BlockchainValues } from '../../../enums/blockchain';
import BlockChainUtils from '../../../utils/BlockChainUtils';
import { getFailedOffsets, getConfirmedOffsets, getLatestOffset } from '../../../utils/OffsetUtils';
import { formatString, truncateMiddle } from '../../../utils/StringUtils';
import config from '../../../config';

interface NFTOffsetOverviewProps {
    data: any
    detailed?: boolean
    hideSensitiveData?: boolean
}

const NFTOffsetOverview: FC<NFTOffsetOverviewProps> = (props) => {
    const [statusTooltipText, setStatusTooltipText] = useState<string>('')
    const [statusIcon, setStatucIcon] = useState<any>()

    const getHref = (url: string) => {
        if (url) {
            return url.includes('//') ? url : `//${url}`;
        } else {
            return '';
        }
    }

    const loadImage = (buffer: any) => {
        if (!buffer || buffer === null || buffer === {}) {
            return '';
        }
        return <img className='overview' src={`data:${buffer.mimetype};base64,${buffer.data}`} alt='' />;
    }

    const getFullBlockchainName = (value: string) => {
        const index = Object.values(BlockchainValues).indexOf(value as unknown as BlockchainValues);
        const key = Object.keys(BlockchainValues)[index];
        const valueIndex = Object.keys(BlockchainName).indexOf(key);
        return Object.values(BlockchainName)[valueIndex];
    };

    useEffect(() => {
        const latestOffset = getLatestOffset(props.data);
        if (latestOffset?.transactionStatus === 'pending') {
            setStatucIcon(<img src={iconPendingPayment} alt='' className='rotate' />);
            setStatusTooltipText('Payment confirmation in progress')
        } else if (latestOffset?.transactionStatus === 'failed') {
            setStatucIcon(<img src={iconPaymentFailed} alt='' />);
            setStatusTooltipText('Payment failed')
        } else if (latestOffset?.transactionStatus === 'confirmed'
            && (latestOffset?.certificateStatus === 'waiting'
                || latestOffset?.certificateStatus === 'pending')) {
            setStatucIcon(<img src={iconPendingCertificate} alt='' className='rotate' />);
            setStatusTooltipText('Waiting for certificate')
        } else if (latestOffset?.transactionStatus === 'confirmed'
            && latestOffset?.certificateStatus === 'failed') {
            setStatucIcon(<img src={iconCertificateFailed} alt='' />);
            setStatusTooltipText('Failed to get certificate')
        } else if (latestOffset?.transactionStatus === 'confirmed'
            && latestOffset?.certificateStatus === 'confirmed') {
            setStatucIcon(<img src={iconSuccess} alt='' />);
            setStatusTooltipText('Certificate delivered')
        }
    }, [props.data]);

    const getCertificate = () => {
        const latestOffset = getLatestOffset(props.data);
        if (latestOffset?.transactionStatus === "pending") {
            return <p className='purple-color' >Confirming payment. It could take up to 90 seconds</p>
        }else if (latestOffset?.transactionStatus === "failed") {
            return <p className='error-color' >Payment <a href={getTransactionHref(latestOffset)} target='_blank' className='error-color'>{truncateMiddle(latestOffset.transactionSignature)}</a> failed.</p>
        }else if (latestOffset?.transactionStatus === "confirmed") {
            switch (latestOffset?.certificateStatus) {
                case 'pending':
                    return <p className='purple-color' >Acquiring certificate. It could take up to 5 minutes</p>
                case 'failed':
                    return <p className='error-color' > Failed to get certificate.</p>
                case 'confirmed':
                    return <a href={latestOffset.certificateLink} className='purple-color' target='_blank' rel='noreferrer'>View certificate</a>

            }
        }
    }

    const getTransactionHref = (offset: any) => {
        switch (offset.paymentNetwork) {
            case 'solana':
                return formatString(config.solana.explorerUrl, offset.transactionSignature)
            case 'binance':
                return formatString(config.binance.explorerUrl, offset.transactionSignature)
        }
    }

    return (
        <>
            <div className={'content-container-inner'}>
                <div className={styles.statusIcon}>
                    <StatusIndicatorTooltip tooltip={statusTooltipText}>
                        {statusIcon}
                    </StatusIndicatorTooltip>
                </div>
                <div className={`${styles.overviewHeader}`}>
                    {loadImage(props.data.logoFile)}
                    <div>
                        <label className='offset-title'>{props.data.projectName}</label>
                        <p className='offset-description'>{props.data.description}</p>
                        {props.data.tags && props.data.tags !== null && props.data.tags !== '' && JSON.parse(props.data.tags)?.map((element: any, i: number) => {
                            return <p key={element.text} className='offset-tags'>#{element.text}&nbsp;</p>
                        }
                        )}
                    </div>
                </div>
                <div className={`${styles.dataRow}`}>
                    <label>website</label>
                    <a href={getHref(props.data.website)} className='purple-color' target='_blank' rel='noreferrer'>{props.data.website.substring(8)}</a>
                </div>
                <div className={`${styles.dataRow}`}>
                    <label>number of items</label>
                    <p>{props.data.count}</p>
                </div>
                <div className={`${styles.dataRow}`}>
                    <label>network</label>
                    <p><img className='option-icon' src={BlockChainUtils.getIcon(props.data.selectedBlockchain)} /> {getFullBlockchainName(props.data.selectedBlockchain)}</p>
                </div>
                {props.data.twitter && props.data.discord && <div className={`${styles.dataRow}`}>
                    <label>social links</label>
                    <p>
                        {props.data.twitter !== '' && <a href={props.data.twitter} target='_blank' ><img className={styles.socialIcons} src={iconTwitter} /></a>}
                        {props.data.discord !== '' && <a href={props.data.discord} target='_blank' ><img className={styles.socialIcons} src={iconDiscord} /></a>}
                    </p>
                </div>}
                {props.detailed &&
                    <>
                        <hr className={styles.lineTopMargin} />
                        <NFTOffsetDetails data={props.data} hideSensitiveData />
                        <div className={`${styles.dataRow}`}>
                            <label>📃 certificate</label>
                            {getCertificate()}
                        </div>
                    </>
                }
            </div>
        </>
    )
};

export default NFTOffsetOverview;
