import './App.css';
import WalletContext from './WalletContext';
import Content from './Content';
import { isMobile } from 'react-device-detect';
import Mobile from './components/mobile/Mobile';

function App(props: any): JSX.Element {
  if(isMobile){
    return (<Mobile></Mobile>);
  }

  return (
    <WalletContext>
      <Content />
    </WalletContext>
  );
}

export default App;