import styles from './TxCount.module.css';
import { useState, useEffect, useRef } from 'react';
import { bscService } from '../../services/blockchainData/bsc/BscService';
import { solanaService } from '../../services/blockchainData/sol/SolanaService';

const TxCount = ({ blockchain, address }: { blockchain: any, address: string }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [txCount, setTxCount] = useState<number>();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        const fetchData = async () => {
            if (blockchain.value === 'bsc') {
                setTxCount((await bscService.getAddressData(address)).transactionData.totalCount);
                setIsLoaded(true);
            }

            if (blockchain.value === 'solana') {
                setTxCount(await solanaService.getTxCount(address, () => !mounted.current));
                setIsLoaded(true);
            }
        }
        if (!isLoading) {
            setIsLoading(true);
            fetchData()
        }
        return () => { mounted.current = false; };
    }, []);

    return (
        <>
            {!isLoaded && <div className={`loadingIndicator dot-flashing inline ${styles.loading}`}></div>}
            {isLoaded && <span>{txCount}</span>}
        </>
    );
};

export default TxCount;