import {FC} from 'react';
import info from '../../../images/icon-info.svg'
import { getLatestOffset } from '../../../utils/OffsetUtils';
interface NFTOffsetOverviewProps {
    data: any
    detailed?: boolean
    hideSensitiveData?: boolean
}
const MessageNotification: FC<NFTOffsetOverviewProps>=(props)=>{

    const getCertificate = () => {
        const latestOffset = getLatestOffset(props.data);
        if(latestOffset?.certificateStatus === "confirmed")
        {   
            return(
            <div className='flex-left' >
            <img src={info} alt=""/>&nbsp;&nbsp; FANTASTIC your NFT collection is GREEN!            
            </div>
            )  
        }else{
            return(
            <div className='flex-left' >
            <img src={info} alt=""/>&nbsp;&nbsp; Presenting your official BADGE - Add it to your webpage! 🎉
            </div>
            ) 
        }
    }

    return(
    <div className={'content-container-inner'}>
        {getCertificate()}
    </div>
    );
}

export default MessageNotification;
