enum BlockchainType {
    SOL = 'sol',
    ETH = 'eth',
    BTC = 'btc',
    BSC = 'bsc',
    DOGE = 'doge',
    ARWEAVE = 'ar',
}
enum BlockchainName {
    SOL = 'Solana (SOL)',
    ETH = 'Ethereum (ETH)',
    BSC = 'Binance Smart Chain (BSC)',
    BTC = 'Bitcoin (BTC)',
    DOGE = 'Dogecoin (DOGE)',
    CUSTOM = 'Custom',
    ARWEAVE = 'Arweave (AR)',
}

enum BlockchainValues {
    SOL = 'solana',
    ETH = 'eth',
    BSC = 'bsc',
    BTC = 'btc',
    DOGE = 'doge',
    CUSTOM = 'custom',
    ARWEAVE = 'arweave',
}


export {
    BlockchainType,
    BlockchainName,
    BlockchainValues
}