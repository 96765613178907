import styles from './Mobile.module.css';
import enrexIcon from '../../images/logo.svg';
import { useState } from 'react';
import axios from 'axios';
import { enrexApi } from '../../services/EnrexApiService';
import arrowIcon from '../../images/icon-arrow.svg';
import laptopImg from '../../images/laptop.svg';
import { isValidEmail } from '../../utils/StringUtils';
import config from '../../config';

const Mobile = () => {
    const [email, setEmail] = useState<string>('');
    const [isEmailInputError, setEmailInputError] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const onEmailInputChange = (email: string) => {
        setEmail(email);

        if(!isValidEmail(email)){
            setEmailInputError(true);
            return;
        }
        
        setEmailInputError(false);
    }

    const submit = async () => {
        if(isEmailInputError||(email.length===0)){
            setEmailInputError(true);
            return;
        }

        let result;
        try {
            result = await enrexApi.get('/createUserUsingPipeDrive', {
                params: {
                    email:email,
                    id:config.pipedrive.id
                },
            });
        } catch (e: any) {
            console.log(e)
        } finally {
        }
        if(result && result.data.result === 'OK'){
            setSubmitted(true);
        }
    }

    return (
        <div className={`root-container ${styles.mobileBackground}`}>
            <p className={styles.greenBlock}><img className={styles.enrexIcon} src={enrexIcon}></img></p><br />

            {!submitted &&
            <>
                <div className={styles.title}>
                    <p className={styles.textBox1}>WELCOME <br/> TO NFT GREENING</p>
                    <p className={styles.textBox2}>Please enter your email address</p>
                </div>
                <div className={styles.inputBox}>
                    <input type="text"
                                    placeholder="Email address"
                                    onChange={(event: any) => { onEmailInputChange(event.target.value); }}
                                    value={email}
                                    className={`${styles.emailInput} ${isEmailInputError ? 'invalid' : ''}`} />
                    <button className={`${styles.submitButton} ${isEmailInputError ? '' : styles.correctSubmitButton}`} onClick={() => submit()}><img src={arrowIcon} className={styles.arrowIcon} /></button>
                </div>
                <p className={styles.textBox3}><p className={`${styles.line} ${styles.lineLeft}`} ></p><p className={styles.lineOr} >OR</p><p className={`${styles.line} ${styles.lineRight}`} ></p></p>
                <div className={styles.demoButtonContainer}><button className={styles.demoButton} onClick={(event) => {window.location.href='https://calendly.com/dainiusenrex/introduction-call';}}>book a demo</button></div>
            </>
            }
            
            {submitted &&
                <>
                    <p className={styles.textBox4}>TNANK YOU</p>
                    <p className={styles.textBox5}>At the moment the app only works on desktop, we are working on it and apologize for the inconvience. Please switch to a bigger screen.</p>
                    <div className={styles.laptopImgContainer}><img className={styles.laptopImg} src={laptopImg} /></div>
                    <div className={styles.homeButtonContainer}><button className={styles.demoButton} onClick={(event) => {window.location.href='https://greeningweb.com/';}} >back to home</button></div>
                </>
            }
        </div>
    );
}

export default Mobile;