import config from "../../config";
import { ethers } from "ethers";
import { ERC20ABI } from "../../utils/ERC20ABI";

declare const window: any;
class BinancePaymentService {

    async processPayment(
        amount: number,
        coin: string,
    ) {
        if (!window.ethereum) {
            window.open("https://metamask.io/", "_blank");
        }
        // METAMASK impl
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const result = await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()

        let tokenDetails;
        switch (coin) {
            case 'usdc':
                tokenDetails = config.binance.usdc;
                break;
            case 'usdt':
                tokenDetails = config.binance.usdt;
                break;
            case 'busd':
                tokenDetails = config.binance.busd;
                break;
            default:
                throw new Error('Unsupported coin');
        }

        // general token send
        let contract = new ethers.Contract(
            tokenDetails.address,
            ERC20ABI,
            signer,
        )

        // How many tokens?
        let numberOfTokens = ethers.utils.parseUnits(amount.toString(), tokenDetails.decimals)
        console.log(`numberOfTokens ${numberOfTokens}`)

        // Send tokens
        let transferResult
        try {
            transferResult = await contract.transfer(config.binance.recipientAddress, numberOfTokens)
        } catch (e: any) {
            console.error(e)
            return { error: `Transaction failed` }
        }
        return { transactionSignature: transferResult.hash };
    }

    getCoinDetails(coin: string) {

    }
}
const paymentService = new BinancePaymentService()

export {
    paymentService,
}
