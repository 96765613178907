import { useState } from 'react';
import iconEstimate from '../../../images/offset-info/icon-estimate.svg';
import iconCalculations from '../../../images/offset-info/icon-calculate.svg';
import iconCalculationsDone from '../../../images/offset-info/icon-calculate-done.svg';
import iconAddWidget from '../../../images/offset-info/icon-add-widget.svg';
import iconAddWidgetDone from '../../../images/offset-info/icon-add-widget-done.svg';
import infoIcon from '../../../images/icon-info.svg';
import iconCloud from '../../../images/icon-cloud.svg';
import iconDelete from '../../../images/icon-close.png'
import addContractIcon from '../../../images/icon-add-contract.svg';
import { WithContext as ReactTags } from 'react-tag-input';
import LoadingButton from '../../loading/LoadingButton';
import { BlockchainName, BlockchainType, BlockchainValues } from '../../../enums/blockchain';
import BlockChainUtils from '../../../utils/BlockChainUtils';
import { isEmail, isWebsiteLink, isTwitterLink, isDiscordLink, validateAnyAddress } from '../../../utils/ValidationUtils';
import Select from 'react-select';
import { enrexApi } from '../../../services/EnrexApiService';
import styles from './Offsets.module.css';
import MiddleEllipsis from 'react-middle-ellipsis';
import { FileUploader } from "react-drag-drop-files";
import TxCount from '../../txCount/TxCount';

const getCryptoLabel = (type: BlockchainValues, name: BlockchainName) => {
    return <div><img className='option-icon' src={BlockChainUtils.getIcon(type)} /> {name}</div>
}
const blockchainOptions = [
    { value: BlockchainValues.SOL, label: getCryptoLabel(BlockchainValues.SOL, BlockchainName.SOL), name: BlockchainName.SOL, isDisabled: false, type: BlockchainType.SOL },
    { value: BlockchainValues.BSC, label: getCryptoLabel(BlockchainValues.BSC, BlockchainName.BSC), name: BlockchainName.BSC, isDisabled: false, type: BlockchainType.BSC },
];

const NFTOffset = () => {
    const [collectionStage, setCollectionStage] = useState<string>('');
    const [projectName, setProjectName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [count, setCount] = useState<string>('');
    const [tags, setTags] = useState<any>([]);
    const [logoFile, setLogoFile] = useState<any>();
    const [websiteLink, setWebsiteLink] = useState<string>('');
    const [twitterLink, setTwitterLink] = useState<string>('');
    const [discordLink, setDiscordLink] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [selectedBlockchain, setSelectedBlockchain] = useState<any>();
    const [smartContractAddress, setSmartContractAddress] = useState<string>('');
    const [smartContractAddressList, setSmartContractAddressList] = useState<any>([]);

    const [isProjectNameValid, setIsProjectNameValid] = useState<boolean>(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(true);
    const [isCountValid, setIsCountValid] = useState<boolean>(true);
    const [isLogoFileValid, setIsLogoFileValid] = useState<any>(true);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isWebsiteLinkValid, setIsWebsiteLinkValid] = useState<boolean>(true);
    const [isTwitterLinkValid, setIsTwitterLinkValid] = useState<boolean>(true);
    const [isDiscordLinkValid, setIsDiscordLinkValid] = useState<boolean>(true);
    const [isSelectedBlockchainValid, setIsSelectedBlockchainValid] = useState<boolean>(true);
    const [isSmartContractAddressValid, setIsSmartContractAddressValid] = useState<boolean>(true);
    const [isSmartContractAddressListValid, setIsSmartContractAddressListValid] = useState<boolean>(true);

    const [fileUploadError, setFileUploadError] = useState<string>();

    const [selectedNetwork, setNetwork] = useState<string>();
    const [selectedCoin, setCoin] = useState<string>();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [resending, setResending] = useState<boolean>(false);
    const [emailVerificationId, setEmailVerificationId] = useState<string>();

    const [emailSent, setEmailSent] = useState<boolean>(false);

    const [consumption, setConsumption] = useState<any>();
    const [receiveBadge, setReceiveBadge] = useState<boolean>(false);

    const [wordLimitReached, setWordLimitReached] = useState<boolean>(false);

    const submit = async (event: any) => {
        if (!canSubmit()) {
            await event.preventDefault();
            scrollToFirstInvalid();
            return false;
        }

        const form = document.querySelector("form");
        const formData = new FormData(form!);
        formData.append('collectionStage', collectionStage!)
        formData.append('logoFile', logoFile)
        formData.append('selectedBlockchain', selectedBlockchain?.value)
        formData.append('smartContractAddressList', JSON.stringify(smartContractAddressList))
        formData.append('tags', JSON.stringify(tags))

        setSubmitting(true)

        enrexApi.post('/saveNftInformation',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                console.log(result)
                setEmailVerificationId(result.data.id)
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setSubmitting(false);
            });

        setEmailSent(true)
    }

    const canSubmit = () => {
        var isGoodToSubmit = true;

        if (!collectionStage || collectionStage === '') {
            isGoodToSubmit = false;
        }

        setIsEmailValid(isEmail(email));
        isGoodToSubmit = isEmail(email) && isGoodToSubmit;

        setIsWebsiteLinkValid(isWebsiteLink(websiteLink));
        isGoodToSubmit = isWebsiteLink(websiteLink) && isGoodToSubmit;

        setIsProjectNameValid(isValid(projectName));
        isGoodToSubmit = isValid(projectName) && isGoodToSubmit;

        setIsDescriptionValid(isValid(description))
        isGoodToSubmit = isValid(description) && isGoodToSubmit;

        setIsCountValid(isValid(count) && Number(count) > 0)
        isGoodToSubmit = isValid(count) && Number(count) > 0 && isGoodToSubmit;

        setIsLogoFileValid(isValid(logoFile))
        isGoodToSubmit = isValid(logoFile) && isGoodToSubmit;

        setIsSelectedBlockchainValid(!!selectedBlockchain)
        isGoodToSubmit = !!selectedBlockchain && isGoodToSubmit;

        setIsSmartContractAddressListValid(collectionStage === 'plan' || smartContractAddressList.length > 0)
        isGoodToSubmit = (collectionStage === 'plan' || smartContractAddressList.length > 0) && isGoodToSubmit;

        return isGoodToSubmit;
    };

    const scrollToFirstInvalid = () => {
        const firstInvalidControl: HTMLElement | null = window.document.querySelector(".warning");
        if (firstInvalidControl) {
            window.scroll({
                top: getTopOffset(firstInvalidControl),
                left: 0,
                behavior: "smooth"
            });
        }
    }

    const getTopOffset = (controlEl: HTMLElement): number => {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    const isValid = (value: any) => {
        if (!value || value === '') {
            return false;
        }
        return true;
    };

    const tagsDelimeters = [188/*comma*/, 13/*enter*/];

    const handleDelete = (i: number) => {
        setTags(tags.filter((tag: any, index: number) => index !== i));
    };

    const handleAddition = (tag: any) => {
        if (tags.length < 10) {
            setTags([...tags, tag]);
        }
    };

    const handleDrag = (tag: any, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index: number) => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const addToAddressList = (address: string) => {
        if (!address || address === '') {
            return;
        }

        if (!selectedBlockchain || !isSmartContractAddressValid) {
            return;
        }

        var list = [...smartContractAddressList];
        if (!smartContractAddressList) {
            list = []
        }

        list.push(address)
        setSmartContractAddressList(list)
        setSmartContractAddress('');
    };

    const deleteFromAddressList = (index: number) => {
        if (smartContractAddressList && smartContractAddressList.length > 0) {
            var list = [...smartContractAddressList];
            list.splice(index, 1);
            setSmartContractAddressList(list);
        }
    }

    const warnAboutWordLimit = (text: string) => {
        setWordLimitReached(text.length >= 200);
    };

    const resendEmailVerification = async () => {
        setResending(true);
        enrexApi.post('/resendVerification', null, {
            params: {
                id: emailVerificationId,
            },
        }).catch((e) => {
            console.error(e)
        }).finally(() => {
            setResending(false);
        });
    }

    return (
        <div className="dapp-container">
            <label className="content-container-inner-label">make your nft collection co<sub>2</sub> neutral via enrex</label>
            <label className="content-container-inner-label">how does it work?</label>
            <div className="content-container-inner noPadding">
                <div className='greening-info-progress-container' >
                    <div className={`greening-info-progress-done width-0 ${emailSent ? 'width-25p' : ''} ${consumption ? 'width-75p' : ''} ${receiveBadge ? 'width-100p' : ''}`} />
                </div>
                <div className="greening-info-img-container">
                    <img src={iconEstimate} alt='' />
                    <img src={consumption ? iconCalculationsDone : iconCalculations} alt='' />
                    <img src={receiveBadge ? iconAddWidgetDone : iconAddWidget} alt='' />
                </div>
                <div className='greening-info-title-container' >
                    <div className={`info`} style={{ left: '100px' }}>
                        <p className='info-title'>fill out the info</p>
                        <p className='info-msg'>about your launched or</p>
                        <p className='info-msg'>planned NFT collection</p>
                    </div>
                    <div className={`info`} style={{ left: '300px' }}>
                        <p className='info-title'>get calculations</p>
                        <p className={`info-msg ${styles.co2LinesSpacing}`}>on energy/CO<sub>2</sub> consumption</p>
                        <p className={`info-msg ${styles.co2LinesSpacing}`}>and offset price estimates</p>
                    </div>
                    <div className={`info`} style={{ left: '500px', width: '170px' }}>
                        <p className='info-title'>receive a badge</p>
                        <p className='info-msg'>to your website to indicate</p>
                        <p className='info-msg'>your collection's greenness</p>
                    </div>
                </div>
            </div>

            {
                !emailSent &&
                <>
                    <form onSubmit={(event: any) => submit(event)}>
                        <label className="content-container-inner-label">your nft collection stage</label>
                        <div className="content-container-inner">
                            <div className='button-container'>
                                <button
                                    type='button'
                                    onClick={() => { setCollectionStage('plan') }}
                                    className={`hoverBorder selectable ${collectionStage === 'plan' ? 'selected' : ''}`}>
                                    planning to launch
                                </button>
                                <button
                                    type='button'
                                    onClick={() => setCollectionStage('launched')}
                                    className={`hoverBorder selectable ${collectionStage === 'launched' ? 'selected' : ''}`}>
                                    already launched
                                </button>
                            </div>
                        </div>

                        {collectionStage &&
                            <>
                                <div className={'content-container-inner'}>
                                    <p className='note'>
                                        <img src={infoIcon} />
                                        We can offset the future NFT minting proccess by providing your upcoming collection's information below
                                    </p>
                                </div>

                                <label className="content-container-inner-label">fill in the information about your nft collection</label>
                                <div className="content-container-inner">
                                    <label className='input-label' htmlFor='projectName'>*Project name</label>
                                    <input id='projectName'
                                        name='projectName'
                                        type="text"
                                        className={`${isProjectNameValid ? '' : 'warning'}`}
                                        onChange={(event: any) => { setProjectName(event.target.value); }}
                                        value={projectName}
                                    />

                                    <label className='input-label' htmlFor='description'>*Description</label>
                                    <textarea id='description'
                                        name='description'
                                        className={`${styles.descriptionTextArea} ${(wordLimitReached || !isDescriptionValid) ? 'warning' : ''}`}
                                        maxLength={200}
                                        onChange={(event: any) => { setDescription(event.target.value); warnAboutWordLimit(event.target.value); }}
                                        value={description} />

                                    <p className='input-details'>Please provide a detailed description of the project (up to 200 symbols - <b>{200 - description.length}</b> left)</p>

                                    <label className='input-label' htmlFor='logo'>*Logo/image</label>
                                    <FileUploader handleChange={(file: any) => { setFileUploadError(''); setLogoFile(file); }}
                                        name="file"
                                        types={["JPG", "PNG", "GIF"]}
                                        maxSize='1'
                                        onSizeError={(error: any) => setFileUploadError(error)}
                                        onTypeError={(error: any) => setFileUploadError(error)}>
                                        <div className={`center file-input ${isLogoFileValid ? '' : 'warning'}`}>
                                            <img src={iconCloud} alt='' />
                                            <p>Drag & Drop your files here or <u>browse</u></p>
                                        </div>
                                    </FileUploader>
                                    <p className='input-details purple-color'>{logoFile?.name}</p>
                                    <p className='input-details error-color'>{fileUploadError}</p>
                                    <p className='input-details'>Square (up to 600x600px) PNG, JPG, GIF up to 1MB</p>

                                    <label className='input-label' htmlFor='count'>*Count of items in the collection</label>
                                    <input id='count'
                                        name='count'
                                        type="number"
                                        placeholder='e.g. 10000'
                                        className={isCountValid ? '' : 'warning'}
                                        onChange={(event: any) => { setCount(event.target.value); }}
                                        value={count}
                                        min={0}
                                    />

                                    <label className='input-label' htmlFor='tags'>Add tags</label>
                                    <ReactTags
                                        tags={tags}
                                        delimiters={tagsDelimeters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        handleDrag={handleDrag}
                                        handleTagClick={handleTagClick}
                                        inputFieldPosition="bottom"
                                        classNames={{ tag: `${styles.tag}`, remove: `${styles.removeTag}` }}
                                        autocomplete
                                        autofocus={false}
                                    />
                                    <p className='input-details'>Separate tags with commas or by pressing enter (max 10)</p>

                                    {collectionStage === 'plan' &&
                                        <>
                                            <label className='input-label' htmlFor='network'>*Primary BC network for minting</label>
                                            <Select options={blockchainOptions}
                                                placeholder="Choose blockchain"
                                                // className={`${styles.regionSelect} ${blockchainSelectError ? 'invalid' : ''}`}
                                                className={isSelectedBlockchainValid ? '' : 'warning'}
                                                classNamePrefix="c-select"
                                                onChange={(blockchain) => { setSelectedBlockchain(blockchain); }}
                                                isSearchable={false} />
                                        </>
                                    }
                                </div>

                                <label className="content-container-inner-label">website address and social media links</label>
                                <div className="content-container-inner">
                                    <label className='input-label' htmlFor='webiste'>*Website link</label>
                                    <input id='website'
                                        name='website'
                                        type="text"
                                        className={isWebsiteLinkValid ? '' : 'warning'}
                                        onChange={(event: any) => { setWebsiteLink(event.target.value); setIsWebsiteLinkValid(isWebsiteLink(event.target.value)); }}
                                        value={websiteLink}
                                        placeholder="https://yournftwebsite.com"
                                    />

                                    <label className='input-label' htmlFor='twitter'>Twitter link</label>
                                    <input id='twitter'
                                        name='twitter'
                                        type="text"
                                        className={isTwitterLinkValid ? '' : 'warning'}
                                        onChange={(event: any) => { setTwitterLink(event.target.value); setIsTwitterLinkValid(isTwitterLink(event.target.value)); }}
                                        value={twitterLink}
                                        placeholder="https://twitter.com/yournftproject"
                                    />

                                    <label className='input-label' htmlFor='discord'>Official Discord server invite link</label>
                                    <input id='discord'
                                        name='discord'
                                        type="text"
                                        className={isDiscordLinkValid ? '' : 'warning'}
                                        onChange={(event: any) => { setDiscordLink(event.target.value); setIsDiscordLinkValid(isDiscordLink(event.target.value)); }}
                                        value={discordLink}
                                        placeholder="https://discord.gg/yournftproject"
                                    />
                                </div>


                                {collectionStage === 'launched' &&
                                    <>
                                        <label className="content-container-inner-label">blockchain network information</label>
                                        <div className="content-container-inner">
                                            <label className='input-label' htmlFor='network'>*Select BC network</label>
                                            <Select options={blockchainOptions}
                                                placeholder="Choose blockchain"
                                                // className={`${styles.regionSelect} ${blockchainSelectError ? 'invalid' : ''}`}
                                                className={isSelectedBlockchainValid ? '' : 'warning'}
                                                classNamePrefix="c-select"
                                                onChange={(blockchain) => { setSelectedBlockchain(blockchain); setSmartContractAddressList([]); setSmartContractAddress(''); }}
                                                isSearchable={false} />

                                            <label className='input-label' htmlFor='smartContractAddress'>*NFT Collection Related Addresses</label>
                                            <input id='smartContractAddress'
                                                name='smartContractAddress'
                                                type="text"
                                                className={`${styles.addContractInput} ${(isSmartContractAddressListValid && isSmartContractAddressValid) ? '' : 'warning'}`}
                                                onChange={(event: any) => { setIsSmartContractAddressValid(validateAnyAddress(event.target.value, selectedBlockchain.value)); setSmartContractAddress(event.target.value) }}
                                                value={smartContractAddress}
                                            />
                                            <button
                                                type='button'
                                                onClick={() => addToAddressList(smartContractAddress!)}
                                                className={`hoverBorder ${styles.addContractButton}`}>
                                                <img src={addContractIcon} className={styles.addPlusImg}></img>
                                            </button>
                                        </div>

                                        <label className="content-container-inner-label">address details</label>
                                        <div className="content-container-inner">
                                            <table className={styles.contractTable}>
                                                <tbody>
                                                    <tr>
                                                        <th>address</th>
                                                        <th>tx count</th>
                                                        <th className={styles.deleteBlockChainHeader}>delete</th>
                                                    </tr>

                                                    {smartContractAddressList && smartContractAddressList.map(function (elem: any, i: number) {
                                                        return (
                                                            <tr key={JSON.stringify(elem) + i + "1"}>
                                                                <td key={JSON.stringify(elem) + i + "2"}><span className={`${styles.ellipsisData} color-purple`}><MiddleEllipsis><span className='purple-color'>{elem}</span></MiddleEllipsis></span></td>
                                                                <td key={JSON.stringify(elem) + i + "3"}><TxCount blockchain={selectedBlockchain} address={elem} /></td>
                                                                <td key={JSON.stringify(elem) + i + "4"} className={styles.deleteBlockChain}><img src={iconDelete} onClick={() => deleteFromAddressList(i)} /></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }


                                <label className="content-container-inner-label">*enter your email address</label>
                                <div className="content-container-inner">
                                    <input id='email'
                                        name='email'
                                        type="text"
                                        className={isEmailValid ? '' : 'warning'}
                                        onChange={(event: any) => { setEmail(event.target.value); setIsEmailValid(isEmail(event.target.value)); }}
                                        value={email} />
                                    <p className='input-details'>Verification letter will be sent to this provided email</p>
                                </div>

                                <div className="container-inner">
                                    <div className="right-align-items">
                                        <LoadingButton onClick={() => console.log()} buttonText='submit' isLoading={submitting} />
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                </>
            }

            {
                emailSent &&
                <>
                    <div className={'content-container-inner'}>
                        <p className='note'>
                            <img src={infoIcon} />
                            An email has been sent to your email address containing a verification link. Please click on the link to activate your account. If email will be missing, refresh your inbox or check spam folder.
                        </p>
                    </div>

                    <div className="right-align-items">
                        <LoadingButton onClick={() => resendEmailVerification()} buttonText='resend email' isLoading={resending} />
                    </div>
                </>
            }
        </div >
    );
};

export default NFTOffset;