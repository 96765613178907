import { FC, useEffect, useState } from 'react';
import NFTOffsetOverview from './NFTOffsetOverview';
import { enrexApi } from '../../../services/EnrexApiService';
import iconError from '../../../images/icon-error.svg';
import LoadingWithError from '../../loading/LoadingWithError';

interface BadgeDetailsProps {
}

const BadgeDetails: FC<BadgeDetailsProps> = (props) => {
    const [offset, setOffset] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get('id');
        if (id) {
            getData(id)
        }
    }, []);

    const getData = async (id: string) => {
        setLoading(true)
        enrexApi.get('/nftInfo', {
            params: {
                id,
            },
        }).then((result) => {
            setOffset(result.data);
        }).catch((e) => {

        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <>
            <LoadingWithError isLoading={loading}
                isError={!offset}
                error={
                    <div className={'content-container-inner'}>
                        <p className='note'>
                            <img src={iconError} />
                            Account not found
                        </p>
                    </div>
                }>
                <NFTOffsetOverview data={offset} detailed hideSensitiveData></NFTOffsetOverview>
            </LoadingWithError>
        </>
    )
};

export default BadgeDetails;
