import { FC } from 'react';
import { round } from '../../../utils/NumberUtils';
import styles from './Offsets.module.css';

interface NFTOffsetDetailsProps {
    data: any
    estimate?: boolean
    hideSensitiveData?: boolean
}

const NFTOffsetDetails: FC<NFTOffsetDetailsProps> = (props) => {
    return (
        <>
            <div className={`${styles.dataRow}`}>
                <label>⚡amount of energy</label>
                <p>{round(props.data.energy, 4)} kWh</p>
            </div>
            <div className={`${styles.dataRow}`}>
                <label>☁️ amount of co<sub>2</sub></label>
                <p>{round(props.data.co2, 4)} g</p>
            </div>
            {!props.hideSensitiveData &&
                <div className={`${styles.dataRow}`}>
                    <label>💸 {props.estimate ? 'estimated' : ''} price</label>
                    <p className='purple-color'>{round(props.data.price)} USD$</p>
                </div>
            }
        </>
    )
};

export default NFTOffsetDetails;
