import { BlockchainType } from '../../enums/blockchain';
import { validate } from 'bitcoin-address-validation';
import { PublicKey } from "@solana/web3.js";
import WAValidator from 'multicoin-address-validator';
import web3 from 'web3';
import { InvalidAddress } from './BlockchainDataService';

const validateAddress = (address: string, blockchain: BlockchainType): void => {
    switch (blockchain as BlockchainType) {
        case BlockchainType.SOL:
            try {
                new PublicKey(address);
            } catch (e) {
                throw new InvalidAddress(`Address ${address} is invalid`)
            }
            break;
        case BlockchainType.BTC:
            if (!validate(address)) {
                throw new InvalidAddress(`Address ${address} is invalid`)
            }
            break;
        case BlockchainType.ETH:
        case BlockchainType.BSC:
            try {
                web3.utils.toChecksumAddress(address)
            } catch (e) {
                throw new InvalidAddress(`Address ${address} is invalid`)
            }
            break;
        case BlockchainType.DOGE:
            if (!WAValidator.validate(address, 'doge', 'both')) {
                throw new InvalidAddress(`Address ${address} is invalid`)
            }
            break;
    }
}

export default validateAddress

