export const getFailedOffsets = (data: any) => {
    return data.offsets?.filter((offset: any) => offset.certificateStatus === 'failed')
}

export const getConfirmedOffsets = (data: any) => {
    return data.offsets?.filter((offset: any) => offset.certificateStatus === 'confirmed')
}

export const getIncompleteCertificates = (data: any) => {
    return data?.offsets?.filter((offset: any) => offset.certificateStatus !== 'confirmed' && offset.certificateStatus !== 'failed')
}

export const getLatestOffset = (data: any) => {
    const res = Math.max.apply(Math, data.offsets?.map((offset: any) => Date.parse(offset.createdAt)))
    return data.offsets?.find((offset: any) => Date.parse(offset.createdAt) === res);
}