import { FC } from 'react';
import { round } from '../../../utils/NumberUtils';
import NFTOffsetDetails from './NFTOffsetDetails';
import styles from './Offsets.module.css';

interface NFTEstimateOverviewProps {
    data: any
}

const NFTEstimateOverview: FC<NFTEstimateOverviewProps> = (props) => {
    const loadImage = (buffer: any) => {
        if (buffer === null || buffer === {}) {
            return '';
        }
        return <img className='overview' src={`data:${buffer.mimetype};base64,${buffer.data}`} alt='' />;
    }

    return (
        <>
            <div className={'content-container-inner'}>
                <div className={`${styles.overviewHeader}`}>
                    {loadImage(props.data.logoFile)}
                    <div style={{ width: '100%' }}>
                        <label className='offset-title'>{props.data.projectName}</label>
                        <p className={styles.purpleLine}/>
                        <NFTOffsetDetails data={props.data} estimate />
                    </div>
                </div>
            </div>
        </>
    )
};

export default NFTEstimateOverview;
