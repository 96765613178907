import validator from 'validator';
import validateAddress from '../services/blockchainData/AddressValidation';
import { BlockchainType } from '../enums/blockchain';

export const isWebsiteLink = (url: string) => {
    return validator.isURL(url);
}

export const isEmail = (email: string) => {
    return validator.isEmail(email);
}

export const isTwitterLink = (url: string) => {
    return url.startsWith('https://twitter.com/');
}

export const isDiscordLink = (url: string) => {
    return url.startsWith('https://discord.gg/');
}

export const isBscAddress = (address: string) => {
    try {
        validateAddress(address, BlockchainType.BSC);
        return true;
    } catch (e) {
        return false;
    }
};

export const isSolanaAddress = (address: string) => {
    try {
        validateAddress(address, BlockchainType.SOL);
        return true;
    } catch (e) {
        return false;
    }
};

export const validateAnyAddress = (address: string, blockchain: string) => {
    if (blockchain === 'bsc') {
        return isBscAddress(address);
    }
    if (blockchain === 'solana') {
        return isSolanaAddress(address);
    }
    return false;
};