
import { FC } from 'react';
import iconError from '../../images/icon-error.svg';
import LoadingWithError from './LoadingWithError';
interface LoadingListProps {
    isLoading?: boolean
    isEmpty?: boolean
}
const LoadingList: FC<LoadingListProps> = (props) => {
    return (
        <>
            <LoadingWithError isLoading={props.isLoading}
                isError={props.isEmpty}
                error={
                    <div className='horizontalContainer'>
                        <img src={iconError} alt='' />
                        <label className='noPadding'>No results found</label>
                    </div>
                } >
                {props.children}
            </LoadingWithError>
        </>

    )
};

export default LoadingList;
