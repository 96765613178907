import { FC, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import badge from '../../../images/badge.svg';
import NFTOffsetOverview from './NFTOffsetOverview';
import { CustomColorPicker } from '../../color/picker/CustomColorPicker';
import { getFilterFromHex } from '../../../utils/HexToRgb';
import { enrexApi } from '../../../services/EnrexApiService';
import OffsetPaymentStage from './payment/OffsetPaymentStage';
import styles from './Offsets.module.css'
import { getIncompleteCertificates, getLatestOffset } from '../../../utils/OffsetUtils';
import config from '../../../config';
import MessageNotification from './MessageNotification';

const badgeSizeOptions = [
    { value: { width: '420px', height: '160px' }, label: '2x (420x160)', },
    { value: { width: '315px', height: '120px' }, label: '1.5x (315x120)', },
    { value: { width: '210px', height: '80px' }, label: '1x (210x80)', },
    { value: { width: '157px', height: '60px' }, label: '0.75x (157x60)', },
    { value: { width: '105px', height: '40px' }, label: '0.5x (105x40)', },
];

interface MyOffsetsProps {
}

const MyOffsets: FC<MyOffsetsProps> = (props) => {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);
    const [offsets, setOffsets] = useState<any[]>([])
    const offsetsRef = useRef<any[]>();
    offsetsRef.current = offsets;
    const [selectedOffset, setSelectedOffset] = useState<any>()
    const selectedOffsetRef = useRef<any[]>();
    const [badgeCode, setBadgeCode] = useState<any>()

    const [badgeColor, setBadgeColor] = useState<any>({ hex: '#454DB4', hsl: { h: 235.67, s: 0.44578, l: 0.488 }, hsv: { h: 235.67, s: 0.61666, v: 0.70588 } })
    const [badgeSize, setBadgeSize] = useState<any>(badgeSizeOptions[0])
    const [badgeColorFilter, setBadgeColorFilter] = useState<any>()

    const [copiedBadget, setCopiedBadget] = useState<boolean>(false);

    useEffect(() => {
        if (cookies.validatedEmails) {
            getData(cookies.validatedEmails.email)
        } else {
            history.push({
                pathname: '/emailValidation',
            });
        }
    }, []);

    useEffect(() => {
        if (cookies.validatedEmails) {
            const intervalObj = setInterval(() => {
                const list = offsetsRef.current;
                if (list?.find((offset: any) => getIncompleteCertificates(offset)?.length > 0)) {
                    getData(cookies.validatedEmails.email)
                }
            }, 30000)
            return () => clearInterval(intervalObj);
        }
    }, []);

    useEffect(() => {
        selectedOffsetRef.current = selectedOffset;
    }, [selectedOffset]);

    const getData = async (email: string) => {
        const currentOffset: any = selectedOffsetRef.current;
        const result = await enrexApi.get('/nftsList', {
            params: {
                email,
            },
        });
        const accountsResult = result.data.map((account: any) => {
            account.label = account.projectName;
            account.value = account.id;
            return account;
        })
        setOffsets(accountsResult);
        if (currentOffset) {
            setSelectedOffset(accountsResult.find((offset: any) => offset.id === currentOffset.id));
        } else {
            setSelectedOffset(accountsResult[0])
        }
    }

    const parseSelected = async (value: any) => {
        setSelectedOffset(value);
    }

    const handleColorChange = (color: any) => {
        setBadgeColor(color)
    };

    useEffect(() => {
        setBadgeColorFilter(getFilterFromHex(badgeColor.hex))
    }, [badgeColor]);

    const isSelectedOffsetTransactionConfirmed = () => {
        return getLatestOffset(selectedOffset)?.transactionStatus === 'confirmed';
    }


    useEffect(() => {
        if (selectedOffset) {
            setBadgeCode(`<a href='${window.location.origin}/nft?id=${selectedOffset.id}' target='_blank'><img src="${config.assetsUrl}/nft/badge.svg" style="width: ${badgeSize.value.width}; height: ${badgeSize.value.height}; filter: ${badgeColorFilter};"/></a>`)
        }
    }, [selectedOffset, badgeSize, badgeColorFilter]);

    return (
        <>
            {offsets &&
                <div className="dapp-container">
                    <label className="content-container-inner-label">my co<sub>2</sub> neutral nft collections</label>
                    <div className="content-container-inner">
                        <Select options={offsets}
                            placeholder="Select NFT Collection"
                            className={``}
                            classNamePrefix="c-select"
                            onChange={(account) => { parseSelected(account); }}
                            value={selectedOffset}
                            isSearchable={false}
                        />
                    </div>

                    {selectedOffset &&
                        <>
                            {isSelectedOffsetTransactionConfirmed() &&
                                <>
                                    <label className="content-container-inner-label">overview</label>
                                    <NFTOffsetOverview data={selectedOffset} detailed></NFTOffsetOverview>

                                    <MessageNotification data={selectedOffset}/>

                                    <label className="content-container-inner-label">website badge preview</label>
                                    <div className={'content-container-inner'}>
                                        <div className='flex-center' style={{ height: badgeSizeOptions[0].value.height }}>
                                            {/* <Badge color={badgeColor} size={badgeSize.value} /> */}
                                            <img src={badge} style={{ filter: badgeColorFilter, width: badgeSize.value.width }} />
                                        </div>
                                    </div>
                                    
                                    <div className={'content-container-inner'}>
                                        <label className='input-label' htmlFor='network'>Select a size:</label>
                                        <Select options={badgeSizeOptions}
                                            placeholder="Choose badge size"
                                            classNamePrefix="c-select"
                                            onChange={(blockchain) => { setBadgeSize(blockchain); }}
                                            isSearchable={false}
                                            value={badgeSize} />

                                        <label className='input-label' htmlFor='network'>Choose a color:</label>
                                        <CustomColorPicker color={badgeColor.hsl} onChange={handleColorChange}></CustomColorPicker>
                                    </div>

                                    <label className="content-container-inner-label">add badge to your website</label>
                                    <div className={'content-container-inner'}>
                                        <p>In order to display Green NFTs badge on your website, add the following lines your template wherever you’d like the badge to appear:</p>
                                        <p className={`note note-accent ${styles.widgetCode}`}>{badgeCode}</p>

                                        <div className="right-align-items">
                                            <button onClick={() => {navigator.clipboard.writeText(badgeCode); setCopiedBadget(true); setTimeout(function(){setCopiedBadget(false);}, 1000);}}>{copiedBadget ? 'copied!' : 'copy'}</button>
                                        </div>
                                    </div>
                                </>
                            }
                            {!isSelectedOffsetTransactionConfirmed() &&
                                <>
                                    <OffsetPaymentStage
                                        offset={selectedOffset}
                                        refreshDataCallback={() => getData(cookies.validatedEmails.email)}>
                                    </OffsetPaymentStage>
                                </>
                            }

                        </>
                    }
                </div>
            }
        </>
    );
};

export default MyOffsets;
