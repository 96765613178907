const LoadingButton = ({ onClick, buttonText, isLoading }: { onClick: Function, buttonText: string, isLoading: boolean }) => {
    return (
        <button className='hoverBorder' onClick={() => onClick()}>
            {!isLoading && buttonText}
            {isLoading && <div className='loadingIndicator dot-flashing inline'></div>}
        </button>
    )
};

export default LoadingButton;
